import { useEffect, useState } from "react";

export interface Coordinates {
  lat: number;
  long: number;
}

export const mergeRefs = (...refs: any) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return (inst: any) => {
    for (const ref of filteredRefs) {
      if (typeof ref === "function") {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

export const roundToNearestMultipleOf = (base: number, multiple: number) =>
  Math.round(base / multiple) * multiple;

export const floorToNearestMultipleOf = (base: number, multiple: number) =>
  Math.floor(base / multiple) * multiple;

// Hook that increments once every second
export function useTimer(start: boolean) {
  // set initial state
  const [time, setTime] = useState(0);
  useEffect(() => {
    setTime(Date.now());
  }, []);

  useEffect(() => {
    // given that we passed in the variable when the timer      should start we use it here
    if (start) {
      const count = setInterval(() => {
        setTime((c) => c + 1000);
      }, 1000);
      return () => clearInterval(count);
    }
    // we keep track when to rerender the hook, aka when the start is changed to true
  }, [start]);

  return time;
}

export const truncateAddress = (address: string, long?: boolean) => {
  const numCharsAtStartOfAddress = long ? 12 : 6;
  const numCharsAtEndOfAddress = long ? 12 : 4;

  return `${address.slice(0, numCharsAtStartOfAddress)}...${address.slice(
    address.length - numCharsAtEndOfAddress
  )}`;
};
