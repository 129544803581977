import styled from "@emotion/styled";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Menu, Image } from "semantic-ui-react";
import { BoxShadows, Breakpoints, Colors, Space } from "../constants/styles";
import logo from "../Intro/assets/navlogo.png";
import { MintModal } from "../MintModal";
import { PresaleMintModal } from "../PresaleMintModal";
import { ConnectButton } from "./ConnectButton";

interface TopRightNavMenuProps {}

const StyledMenu = styled(Menu)`
  font-family: "Avenir Next", sans-serif !important;
  font-size: ${Space[16]} !important;
  color: ${Colors.whiteAlpha[900]} !important;
  border: none !important;
`;

const StyledSegment = styled.div`
  background-color: ${Colors.gray[900]};
  position: sticky;
  top: -1px;
  padding: ${Space[8]} 0;
  border: none;
  border-radius: none;
  box-shadow: ${BoxShadows.smWhite};
  z-index: 9999;
`;

const StyledMenuWrapper = styled.div`
  max-width: 540px;
  margin: 0 auto;
  @media (min-width: ${Breakpoints.sm}) {
    max-width: 540px;
    margin: ${Space[64]}px auto ${Space[32]}px;
  }
  @media (min-width: ${Breakpoints.md}) {
    max-width: 780px;
  }
  @media (min-width: ${Breakpoints.lg}) {
    max-width: 960px;
    margin: ${Space[32]}px auto;
  }
  @media (min-width: ${Breakpoints.xl}) {
    max-width: 1280px;
  }
`;
const StyledSubmenu = styled(Menu.Menu)`
  display: flex;
  align-items: center;
`;

const StyledMenuItem = styled(Menu.Item)`
  :hover {
    color: ${Colors.whiteAlpha[700]} !important;
    background: ${Colors.gray[900]} !important;
  }
`;

const StyledMenuItemHiddenOnMobile = styled(StyledMenuItem)`
  @media (max-width: ${Breakpoints.md}) {
    display: none !important;
  }
`;

const MenuLaunchButton = styled(Button)``;

const LogoImage = styled(Image)`
  max-height: 64px;
  @media (max-width: ${Breakpoints.md}) {
    max-width: 200px !important;
  }
`;

export const TopRightNavMenu: React.FC<TopRightNavMenuProps> = () => {
  const [shown, setShown] = useState(false);
  const showModal = () => setShown(true);
  const closeModal = () => setShown(false);
  const location = useLocation();
  return (
    <React.Fragment>
      <PresaleMintModal shown={shown} closeModal={closeModal} />
      <StyledSegment>
        <StyledMenuWrapper>
          <StyledMenu inverted pointing secondary>
            <Link to="/">
              <LogoImage src={logo} alt="Fusion Apes" />
            </Link>
            <StyledSubmenu position="right">
              <a href="/#about">
                <StyledMenuItemHiddenOnMobile name="About" />
              </a>
              {location.pathname === "/mint" ? (
                <ConnectButton />
              ) : (
                <Menu.Item>
                  <Link to="/mint">
                    <MenuLaunchButton onClick={showModal} primary>
                      Mint
                    </MenuLaunchButton>
                  </Link>
                </Menu.Item>
              )}
            </StyledSubmenu>
          </StyledMenu>
        </StyledMenuWrapper>
      </StyledSegment>
    </React.Fragment>
  );
};
