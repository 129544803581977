import React from "react";
import styled from "@emotion/styled";
import { Breakpoints, Space } from "../constants/styles";
import { HeroHeader } from "./Landing.styled";
import { ResponsiveButtonBar } from "./ResponsiveButtonBar";
import { LaunchAppButton, ReadWhitePaperButton } from "./LandingButton";
import heroGif from "./assets/landingpromo.gif";
import { DISCORD_URL, TWITTER_URL } from "../constants/urls";

const TestimonialsHeroDiv = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column;
    align-items: center;
  }

  margin-bottom: ${Space[96]};
`;

const FlexChildDiv = styled.div``;

const HeroTextDiv = styled(FlexChildDiv)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: ${Breakpoints.md}) {
    padding-right: ${Space[32]};
  }
  @media (max-width: ${Breakpoints.md}) {
    margin-bottom: ${Space[32]};
  }
  h1 {
    margin-bottom: ${Space[12]};
  }
`;

const HeroImage = styled.img`
  width: 450px;
  height: 450px;
  @media (max-width: ${Breakpoints.md}) {
    margin: 0 auto;
    width: 250px;
    height: 250px;
  }
`;

const CarouselDiv = styled(FlexChildDiv)`
  // This isn't the most ideal for generalized code but due to the weird
  // shittiness of a 3-million width carousel from react-slick, it doesn't obey
  // normal flex rules.
  // This breakpoint should be the same as whatever causes stuff to go into
  // columns.
  @media (min-width: ${Breakpoints.md}) {
    // Also, since the arrows are somehow absolutely positioned outside the
    // container, we need to do < 50% to make room for it.
    max-width: 40%;
  }
`;

const HeroTextSpan = styled.span`
  font-size: ${Space[20]};
  line-height: 1.4;
`;

export const HeroSection: React.FC = () => {
  return (
    <TestimonialsHeroDiv>
      <HeroTextDiv>
        <HeroHeader>The final evolution</HeroHeader>
        <HeroTextSpan>
          Introducing Fusion Apes — re-imaginations of the classic ape
          silhouettes in an art style inspired by Fidenzas. Individually drawn
          using the power of neural networks. Own a piece in the first series
          applying deep learning to create a style-fusion inspired by two
          landmark NFT projects. Only 4,200 Fusion Apes will ever be created.
          Join our <a href={DISCORD_URL}>Discord</a> and follow our{" "}
          <a href={TWITTER_URL}>Twitter</a> to keep updated on our drop
          scheduled for September 3rd at 8pm EDT.
        </HeroTextSpan>
        <div style={{ marginTop: Space[24] }}>
          <ResponsiveButtonBar
            buttons={[<LaunchAppButton />, <ReadWhitePaperButton />]}
          />
        </div>
      </HeroTextDiv>
      <CarouselDiv>
        <HeroImage width="450" height="450" alt="Fusion Ape" src={heroGif} />
      </CarouselDiv>
    </TestimonialsHeroDiv>
  );
};
