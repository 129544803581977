import React from "react";
import "./App.css";

import "semantic-ui-css/semantic.min.css";
import { BrowserRouter as Router } from "react-router-dom";
import { css, Global } from "@emotion/react";
import { Routes } from "./Routes";
import { Web3ProviderModal } from "./Web3ProviderModal/Web3ProviderModal";
import { Web3ProviderModalConnector } from "./Web3ProviderModal/Web3ProviderModalConnector";

function App() {
  return (
    <div className="App">
      <Global
        styles={css`
          @font-face {
            font-family: "Avenir Next";
            src: url("/static/avenir-next-regular.woff") format("woff");
            font-weight: normal;
            font-style: normal;
          }
          @font-face {
            font-family: "Avenir Next";
            src: url("/static/avenir-next-demi.woff") format("woff");
            font-weight: bold;
            font-style: normal;
          }
        `}
      />
      <Router>
        <Routes />
      </Router>

      <Web3ProviderModalConnector Render={Web3ProviderModal} />
    </div>
  );
}

export default App;
