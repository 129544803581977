import React from "react";
import { FadeInOutDiv } from "./components/FadeInOutDiv";
import { FooterSection } from "./Intro/FooterSection";
import { HeroSection } from "./Intro/HeroSection";
import { HowItWorksSection } from "./Intro/HowItWorksSection";
import { makeLandingSections } from "./Intro/landing-helpers";
import { LandingSection } from "./Intro/LandingSection";
import { ReadySection } from "./Intro/ReadySection";
import { SocialMediaSection } from "./Intro/SocialMediaSection";
import { TopRightNavMenu } from "./NavMenu/TopRightNavMenu";

interface IntroProps {}

const landingSectionChildren: [
  React.ReactNode,
  Partial<React.ComponentProps<typeof LandingSection>>?
][] = [
  [<HeroSection />],
  [<HowItWorksSection />],
  [<ReadySection />],
  [<SocialMediaSection />],
  [<FooterSection />],
];

export const Intro: React.FC<IntroProps> = () => {
  return (
    <>
      <TopRightNavMenu />

      <FadeInOutDiv duration={0.5}>
        {makeLandingSections(landingSectionChildren)}
      </FadeInOutDiv>
    </>
  );
};
