import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import { APP_NAME } from "./constants";
import { isTestEnv } from "./constants/env";

const SUPPORTED_CHAINS_PROD = [1];
const SUPPORTED_CHAINS_TEST = [
  1, // Mainet
  4, // Rinkeby
];

export const injected = new InjectedConnector({
  supportedChainIds: isTestEnv()
    ? SUPPORTED_CHAINS_TEST
    : SUPPORTED_CHAINS_PROD,
});

const POLLING_INTERVAL = 12000;

// TODO(louisli): REACT_APP_ALCHEMY_MAINNET_URL can be deprecated
// everything is expoed on client-side anyway, and move it to a constant here
// for cleanliness and less fiddling.
const ALCHEMY_RINKEBY_URL =
  "https://eth-rinkeby.alchemyapi.io/v2/p1ftZiOVsWTwL-1TmAgwtc47HWUbj7D5";

export const walletLink = new WalletLinkConnector({
  url: isTestEnv()
    ? ALCHEMY_RINKEBY_URL
    : process.env.REACT_APP_ALCHEMY_MAINNET_URL!,
  appName: APP_NAME,
});

export const walletConnect = new WalletConnectConnector({
  rpc: isTestEnv()
    ? {
        1: process.env.REACT_APP_ALCHEMY_MAINNET_URL!,
      }
    : {
        4: ALCHEMY_RINKEBY_URL,
      },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
});
