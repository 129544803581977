import React from "react";
import styled from "@emotion/styled";
import { Space, Breakpoints } from "../constants/styles";

const ButtonsDiv = styled.div`
  margin-top: ${Space[24]};
  display: flex;
  > div {
    margin-right: ${Space[18]};
    margin-bottom: ${Space[12]};
    vertical-align: top;
  }
  > div:last-child {
    margin-right: 0;
  }
  @media (max-width: ${Breakpoints.sm}) {
    flex-direction: column;
    align-items: center;
    > div {
      margin-right: 0;
    }
  }
`;

interface ResponsiveButtonBarProps {
  buttons: React.ReactNode[];
}

export const ResponsiveButtonBar: React.FC<ResponsiveButtonBarProps> = ({
  buttons,
}) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <ButtonsDiv>
        {buttons.map((button, index) => (
          <div key={index}>{button}</div>
        ))}
      </ButtonsDiv>
    </div>
  );
};
