import React, { useState } from "react";
import { WrapperDiv } from "./components/WrapperDiv";
import {
  DISCORD_URL,
  OPENSEA_COLLECTION_URL,
  UNREVEALED_URL,
} from "./constants/urls";
import { Dropdown, Image } from "semantic-ui-react";
import { TopRightNavMenu } from "./NavMenu/TopRightNavMenu";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { MintButton } from "./MintButton";
import { ConnectButton } from "./NavMenu/ConnectButton";

interface IntroProps {}

const options = [
  { key: 1, text: "1", value: 1 },
  { key: 2, text: "2", value: 2 },
  { key: 3, text: "3", value: 3 },
  { key: 4, text: "4", value: 4 },
  { key: 5, text: "5", value: 5 },
  { key: 6, text: "6", value: 6 },
  { key: 7, text: "7", value: 7 },
  { key: 8, text: "8", value: 8 },
  { key: 9, text: "9", value: 9 },
  { key: 10, text: "10", value: 10 },
];

export const Mint: React.FC<IntroProps> = () => {
  const [mintCount, setMintCount] = useState(0);

  const handleChange: any = (e: any, obj: any) => setMintCount(obj.value);
  const { active, account } = useWeb3React<ethers.providers.Web3Provider>();

  return (
    <>
      <TopRightNavMenu />
      <WrapperDiv>
        <span style={{ fontSize: 18 }}>
          {/* The public sale is now <b>LIVE</b>!
          <br />
          <Dropdown
            style={{ marginTop: 12, marginBottom: 12, marginRight: 18 }}
            onChange={handleChange}
            value={mintCount}
            options={options}
            placeholder="# to mint"
            selection
          /> */}
          The sale is all sold out! Check us out on{" "}
          <a href={OPENSEA_COLLECTION_URL}>OpenSea</a>!
          <br />
          Stay tuned and join our <a href={DISCORD_URL}>Discord</a> for the
          latest updates on the reveal!
          <br />
          {/* {active && account ? (
            <MintButton mintCount={mintCount} />
          ) : (
            <ConnectButton />
          )} */}
          {/* <br />
          <h3>Official Details</h3>
          - Price: 0.1 Ξ per NFT
          <br />- Mint Limit: 10 per transaction
          <br />- Supply: 4200 available in sale. */}
          <br />
        </span>
        <br />
        <Image size="medium" src={UNREVEALED_URL} />
      </WrapperDiv>
    </>
  );
};
