import * as React from "react";
import { css } from "@emotion/css";
import { Item } from "semantic-ui-react";
import { web3ProviderTypes } from "../types";

const clickable = css`
  cursor: pointer;
  flex-direction: row !important;
`;

const contentForcePadding = css`
  padding: 0 0 0 21px !important;
`;

interface Web3ProviderItemProps {
  imageSrc: string;
  providerType: web3ProviderTypes;
  header: string;
  meta: string;
  onClick: () => void;
}

const Web3ProviderItem: React.FunctionComponent<Web3ProviderItemProps> = ({
  imageSrc,
  header,
  meta,
  onClick,
}) => {
  return (
    <Item className={clickable} onClick={onClick}>
      <Item.Image size="tiny" src={imageSrc} />
      <Item.Content className={contentForcePadding}>
        <Item.Header>{header}</Item.Header>
        <Item.Meta>{meta}</Item.Meta>
      </Item.Content>
    </Item>
  );
};

export default Web3ProviderItem;
