import styled from "@emotion/styled";
import React from "react";
import { Image } from "semantic-ui-react";
import { Breakpoints, Space } from "../constants/styles";
import discordImage from "./assets/dLogo.png";
import twitterImage from "./assets/twLogo.png";

import openseaImage from "./assets/opensealogo.png";
import { SectionHeader } from "./Landing.styled";
import {
  DISCORD_URL,
  OPENSEA_COLLECTION_URL,
  TWITTER_URL,
} from "../constants/urls";

interface SocialMediaSectionProps {}

const SocialMediaSectionDiv = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: ${Breakpoints.md}) {
    flex-direction: column;
  }
`;

const SocialMediaIconsDiv = styled.div`
  display: flex;
  width: 90%;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-evenly;
  margin: ${Space[48]} auto;
`;

const ResponsiveSocialMediaImage = styled(Image)`
  @media (max-width: ${Breakpoints.md}) {
    width: 50px !important;
  }
`;

export const SocialMediaSection: React.FC<SocialMediaSectionProps> = () => {
  return (
    <>
      <SectionHeader>Join our community</SectionHeader>
      <SocialMediaSectionDiv>
        <SocialMediaIconsDiv>
          <a href={TWITTER_URL}>
            <ResponsiveSocialMediaImage src={twitterImage} size="tiny" />
          </a>
          <a href={DISCORD_URL}>
            <ResponsiveSocialMediaImage src={discordImage} size="tiny" />
          </a>

          <a href={OPENSEA_COLLECTION_URL}>
            <ResponsiveSocialMediaImage src={openseaImage} size="tiny" />
          </a>
        </SocialMediaIconsDiv>
      </SocialMediaSectionDiv>
    </>
  );
};
