import detectEthereumProvider from "@metamask/detect-provider";
import { useCallback, useState } from "react";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { useAppDispatch } from "../slices";
import { closeModal as closeModalAction, openModal } from "../slices/web3Modal";
import { injected, walletConnect, walletLink } from "../connectors";
import { web3ProviderTypes } from "../types";

const checkMetamaskProvider = async (
  setMetamaskInstallStatus: (status: boolean) => void
) => {
  const provider = await detectEthereumProvider({ mustBeMetaMask: true });
  setMetamaskInstallStatus(!!provider);
};

export const useMetamaskInstalled = () => {
  const [isMetamaskInstalled, setMetamaskInstalled] = useState(false);
  checkMetamaskProvider(setMetamaskInstalled);

  return isMetamaskInstalled;
};

export const useWalletConnectors = () => {
  const dispatch = useAppDispatch();
  const closeWeb3Modal = useCallback(() => dispatch(closeModalAction()), [
    dispatch,
  ]);

  const { activate } = useWeb3React<ethers.providers.Web3Provider>();

  const handleMetaMask = useCallback(() => {
    activate(injected)
      .then(() =>
        localStorage.setItem("lastConnector", web3ProviderTypes.Metamask)
      )
      .catch((err) => console.log(err));
    closeWeb3Modal();
  }, [activate, closeWeb3Modal]);

  const handleWalletConnect = useCallback(() => {
    activate(walletConnect).then(() =>
      localStorage.setItem("lastConnector", web3ProviderTypes.WalletConnect)
    );
    closeWeb3Modal();
  }, [activate, closeWeb3Modal]);

  const handleWalletLink = useCallback(() => {
    activate(walletLink).then(() =>
      localStorage.setItem("lastConnector", web3ProviderTypes.CoinbaseWallet)
    );
    closeWeb3Modal();
  }, [activate, closeWeb3Modal]);

  return {
    handleMetaMask,
    handleWalletConnect,
    handleWalletLink,
  };
};

export const useWeb3Connect = () => {
  const {
    handleWalletConnect,
    handleMetaMask,
    handleWalletLink,
  } = useWalletConnectors();

  const dispatch = useAppDispatch();
  const openWeb3Modal = () => dispatch(openModal());

  const connectorFromProvider = (providerType: web3ProviderTypes | null) => {
    switch (providerType) {
      case web3ProviderTypes.Metamask:
        return handleMetaMask;
      case web3ProviderTypes.CoinbaseWallet:
        return handleWalletLink;
      case web3ProviderTypes.WalletConnect:
        return handleWalletConnect;
      default:
        return openWeb3Modal;
    }
  };

  const web3Connect = connectorFromProvider(localStorage.getItem(
    "lastConnector"
  ) as web3ProviderTypes);

  return {
    web3Connect,
  };
};
