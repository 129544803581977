export const TWITTER_URL = "https://twitter.com/fusionapes";
export const DISCORD_URL = "https://discord.gg/zrcHuuBDc7";

export const METAMASK_INSTALL_URL = "https://metamask.io/";

export const OPENSEA_COLLECTION_URL = "https://opensea.io/collection/fusionape";

export const UNREVEALED_URL =
  "https://fusionapes.s3.us-east-2.amazonaws.com/unrevealed.gif";

export const PROD_CONTRACT_ADDRESS =
  "0xEA6504BA9ec2352133e6A194bB35ad4B1a3b68e7";

export const TEST_CONTRACT_ADDRESS =
  "0xEA6504BA9ec2352133e6A194bB35ad4B1a3b68e7";

export const ETHER_PRICE_OF_MINT = 0.1;
