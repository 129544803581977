import { AnimatePresence } from "framer-motion";
import React from "react";
import { Route, Switch } from "react-router-dom";
import { Intro } from "./Intro";
import { Mint } from "./Mint";

export const Routes: React.FC = () => {
  return (
    <AnimatePresence>
      <Switch>
        <Route path="/" exact component={Intro} />
        <Route path="/intro" exact component={Intro} />
        <Route path="/mint" component={Mint} />
      </Switch>
    </AnimatePresence>
  );
};
