// Dimensions here are expressed in pixels.
export const BLOCK_WIDTH = 4;
export const BLOCK_HEIGHT = 4;
export const CANVAS_WIDTH = 1024;
export const CANVAS_HEIGHT = 1024;

export const SCALE_FACTOR = 5;
export const DETAIL_SCALE_FACTOR = 20;
// export const UNZOOMED_SCALE_FACTOR = 1440.0 / CANVAS_HEIGHT;

export const UNZOOMED_SCALE_FACTOR = 1;

export const NUM_BLOCKS =
  (CANVAS_HEIGHT * CANVAS_WIDTH) / (BLOCK_HEIGHT * BLOCK_WIDTH);

export const DEFAULT_SHIFT = 64;

export const TOKEN_PAGE_SIZE = 50;

export const MAX_OFFSET = 128;

export const APP_NAME = "Fusion Apes";

export const NUM_COLORS = 32;

export const NAV_BUTTONS_VERTICAL_OFFSET = 18;

export const OPENSEA_CONTRACT_OWNER_BALANCE = 2000;

export const MIN_BID_AMOUNT_IN_ETH = 0.1;
