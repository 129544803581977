import { css } from "@emotion/css";
import { Button, Image, Modal } from "semantic-ui-react";
import { Colors } from "./constants/styles";
import { UNREVEALED_URL } from "./constants/urls";

const modalStyle = css`
  z-index: 9999 !important;
`;

interface MintModalProps {
  shown: boolean;
  closeModal: () => void;
}

export const PresaleMintModal: React.FunctionComponent<MintModalProps> = ({
  shown,
  closeModal,
}) => {
  return (
    <Modal closeIcon open={shown} onClose={closeModal}>
      <Modal.Header>Mint details</Modal.Header>
      <Modal.Content>
        <span style={{ fontSize: 18 }}>
          Pre-sale is now <b>LIVE</b>!
          <br />
          <Button
            style={{
              backgroundColor: Colors.mainPrimary,
              color: Colors.whiteAlpha[900],
            }}
            size="large"
          >
            Mint
          </Button>
          <br />
          <b>Official Details</b>
          <br />- Price: 0.1 Ξ per NFT
          <br />- Mint Limit: 10 per transaction
          <br />- Supply: 420 available in presale.
          <br />
        </span>
        <Image size="medium" src={UNREVEALED_URL} />
      </Modal.Content>
    </Modal>
  );
};
