import React from "react";
import { LandingSection } from "./LandingSection";

/**
 * Wraps each section in LandingSection,
 * alternating background colors based on index.
 */
export const makeLandingSections = (
  sections: [
    React.ReactNode,
    Partial<React.ComponentProps<typeof LandingSection>>?
  ][]
) => {
  return sections.map(([sectionChild, landingSectionProps], index) => (
    <LandingSection
      withBackgroundColor={index % 2 === 0}
      {...landingSectionProps}
    >
      {sectionChild}
    </LandingSection>
  ));
};

/**
 * Depending on the number of sections before it,
 * invert the color of the footer so that it alternates correspondingly.
 */
export const shouldFlipFooterColor = (
  precedingSectionsLength: number
): boolean => {
  return precedingSectionsLength % 2 !== 0;
};
