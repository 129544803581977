import { css } from "@emotion/css";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import React from "react";
import { Button, Icon } from "semantic-ui-react";
import {
  Breakpoints,
  buttonColor,
  Colors,
  Space,
  SystemFontStack,
} from "../constants/styles";
import { truncateAddress } from "../utils";
import { useWeb3Connect } from "../Web3ProviderModal/walletHooks";

export const baseButtonStyle = css`
  background-color: ${buttonColor} !important;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06),
    0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  color: ${Colors.whiteAlpha[900]} !important;
  margin-right: ${Space[18]} !important;
  font-family: ${SystemFontStack} !important;
  font-weight: 500 !important;
  @media (max-width: ${Breakpoints.lg}) {
    margin-right: ${Space[12]} !important;
  }
`;

const buttonStyle = css`
  margin: 0px auto !important;
  width: 80%;
`;

const inFormConnectButtonStyle = css`
  ${baseButtonStyle}
  ${buttonStyle}
  box-shadow: none !important;
`;

interface ConnectButtonProps {
  inForm?: boolean;
}

export const ConnectButton: React.FC<ConnectButtonProps> = ({ inForm }) => {
  const context = useWeb3React<ethers.providers.Web3Provider>();
  const { active, account, deactivate, error } = context;
  console.log(active);
  const logout = () => {
    deactivate();
    localStorage.removeItem("lastConnector");
  };
  const { web3Connect } = useWeb3Connect();
  if (error instanceof UnsupportedChainIdError) {
    return (
      <Button
        circular
        onClick={logout}
        className={inForm ? inFormConnectButtonStyle : baseButtonStyle}
      >
        <Icon name="close" />
        <span>Unsupported Chain</span>
      </Button>
    );
  }
  if (active && account) {
    return (
      <Button
        circular
        onClick={logout}
        className={inForm ? inFormConnectButtonStyle : baseButtonStyle}
      >
        <Icon name="close" />
        <span>{truncateAddress(account)}</span>
      </Button>
    );
  }
  return (
    <Button
      circular
      onClick={web3Connect}
      className={inForm ? inFormConnectButtonStyle : baseButtonStyle}
    >
      Connect
    </Button>
  );
};
