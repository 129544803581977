import styled from "@emotion/styled";
import { Breakpoints, Colors, Space } from "../constants/styles";

// Special font that we're only using sometimes on the homepage.
export const TITLE_FONT = "'Montserrat', sans-serif";
export const TEXT_FONT = "'Montserrat', sans-serif";

// Gives box shadow to different elements such as testimonials
// Medium tailwind box-shadow
// https://github.com/tailwindlabs/tailwindcss/blob/master/stubs/defaultConfig.stub.js
export const BOX_SHADOW =
  "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);";

// Container div that wraps pages. Should be used at the top-level around
// "sections."
export const StandardContainerDiv = styled.div`
  width: 100%;
  margin: 0 auto;
  color: ${Colors.neutrals[1000]};
`;

// Use this instead of StandardContainerDiv when you need a wider space, such as
// in heroes.
export const ExtraWideContainerDiv = styled(StandardContainerDiv)`
  max-width: 1200px;
  padding: 0 ${Space[8]};
`;

// Should basically only appear once per page. The biggest and baddest text.
export const HeroHeader = styled.h1`
  font-family: ${TITLE_FONT};
  font-size: 54px;
  @media (max-width: ${Breakpoints.sm}) {
    font-size: ${Space[36]};
  }
`;

export const SectionHeader = styled.h2`
  font-family: ${TITLE_FONT};
  font-size: ${Space[36]};
  text-align: center;
  @media (max-width: ${Breakpoints.sm}) {
    font-size: ${Space[28]};
  }
`;

export const SectionSubheader = styled.div`
  font-size: 18px;
  padding: 0px ${Space[64]};
  text-align: center;
  margin-bottom: ${Space[64]};
  @media (max-width: ${Breakpoints.sm}) {
    padding: 0 ${Space[8]};
  }
`;

export const CarouselContainerDiv = styled.div`
  max-width: 825px;
  margin: 0 auto;
  margin-top: ${Space[64]};
`;
