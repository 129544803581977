import styled from "@emotion/styled";
import { Breakpoints } from "../constants/styles";

// Inspired by Bootstrap container sizes.
// This is reused multiple times throughout the main layout, so be careful with
// changes to this.
export const Container = styled.div`
  margin: 0 auto;
  max-width: 540px;
  @media (min-width: ${Breakpoints.sm}) {
    max-width: 540px;
  }
  @media (min-width: ${Breakpoints.md}) {
    max-width: 720px;
  }
  @media (min-width: ${Breakpoints.lg}) {
    max-width: 960px;
  }
  @media (min-width: ${Breakpoints.xl}) {
    max-width: 1140px;
  }
`;
