import React from "react";
import { AlternatingImageItemsSection } from "./AlternatingImageItemsSection";
import { HOW_IT_WORKS } from "./spec";

export const HowItWorksSection: React.FC = () => (
  <>
    <AlternatingImageItemsSection
      spec={HOW_IT_WORKS}
      isStepIndexShown={false}
    />
  </>
);
