import { motion } from "framer-motion";
import React from "react";

const variants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

interface FadeInOutDivProps {
  duration: number;
  fadeInOnly?: boolean;
}

export const FadeInOutDiv: React.FC<FadeInOutDivProps> = ({
  duration,
  children,
  fadeInOnly,
}) => {
  const transition = {
    type: "tween",
    duration,
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit={fadeInOnly ? undefined : "out"}
      variants={variants}
      transition={transition}
    >
      {children}
    </motion.div>
  );
};
