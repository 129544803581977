/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from "@emotion/styled";
import React from "react";
import {
  Colors,
  Space,
  Breakpoints,
  SystemFontStack,
} from "../constants/styles";
import { DISCORD_URL, TWITTER_URL } from "../constants/urls";

const FooterSpec = [
  {
    title: "Product",
    children: [
      {
        text: "Intro",
        href: "/",
      },
      {
        text: "About",
        href: "#about",
      },
    ],
  },

  {
    title: "Community",
    children: [
      {
        text: "Twitter",
        href: TWITTER_URL,
        shouldUseVanillaLink: true,
      },
      {
        text: "Discord",
        href: DISCORD_URL,
        shouldUseVanillaLink: true,
      },
    ],
  },
];

const FooterDiv = styled.div`
font-family: ${SystemFontStack}
  max-width: ${Breakpoints.md};
  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
    flex-direction: column;
    > div {
      margin-top: ${Space[16]};
    }
    align-items: center;
  }
  margin: ${Space[24]} auto;
  display: flex;
  justify-content: space-between;
`;

const FooterColumnTitle = styled.div`
  font-size: ${Space[16]};
  margin-bottom: ${Space[8]};
  color: ${Colors.whiteAlpha[900]};
`;

const FooterSmallColumnTitle = styled.div`
  margin-bottom: ${Space[8]};
  color: ${Colors.whiteAlpha[900]};
`;

const FooterFlexChild = styled.div`
  // Since these children are aligned center by the flex, this is kinda used to
  // determine how much in the center it is. If you don't have this, the children
  // will be determined by their text-size and ones with longer links will be
  // slightly off-center.
  min-width: 200px;
  a {
    margin: ${Space[8]} 0;
    font-size: ${Space[12]};
    display: block;
    @media (max-width: ${Breakpoints.sm}) {
      margin: ${Space[12]} 0;
    }
    color: ${Colors.whiteAlpha[900]};
  }
  a:hover {
    color: ${Colors.whiteAlpha[700]};
  }
  img {
    width: ${Space[24]};
    margin-right: ${Space[16]};
  }
`;

const CopyrightSpan = styled.span`
  font-size: ${Space[12]};
`;

export const FooterSection: React.FC = () => (
  <FooterDiv>
    <FooterFlexChild>
      <FooterSmallColumnTitle>Built in 2021</FooterSmallColumnTitle>
      <CopyrightSpan>Fusion Apes</CopyrightSpan>
    </FooterFlexChild>
    {FooterSpec.map((column) => (
      <FooterFlexChild key={column.title}>
        <FooterColumnTitle>{column.title}</FooterColumnTitle>
        {column.children.map((child) => (
          <a href={child.href} key={child.text}>
            <a>{child.text}</a>
          </a>
        ))}
      </FooterFlexChild>
    ))}
  </FooterDiv>
);
