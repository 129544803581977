import styled from "@emotion/styled";
import React from "react";
import { Space, Breakpoints } from "../constants/styles";

export const WrapperDiv = styled.div`
  margin: ${Space[24]} auto 32px;
  max-width: 540px;
  background-color: #edf2f7;
  @media (min-width: ${Breakpoints.sm}) {
    max-width: 540px;
    margin: ${Space[64]}px auto ${Space[32]}px;
  }
  @media (min-width: ${Breakpoints.md}) {
    max-width: 720px;
  }
  @media (min-width: ${Breakpoints.lg}) {
    max-width: 960px;
    margin: ${Space[32]}px auto;
  }
  @media (min-width: ${Breakpoints.xl}) {
    max-width: 1140px;
  }
  text-align: left;
  padding: 18px;
  color: rgba(0, 0, 0, 0.92);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 24px;

  hr {
    opacity: 0.33;
  }
`;
