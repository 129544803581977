import styled from "@emotion/styled";
import React from "react";
import { Space, Breakpoints } from "../constants/styles";
import { DISCORD_URL, TWITTER_URL } from "../constants/urls";
import { TITLE_FONT } from "./Landing.styled";
import { LaunchAppButton, ReadWhitePaperButton } from "./LandingButton";
import { ResponsiveButtonBar } from "./ResponsiveButtonBar";

const CallToActionDiv = styled.div`
  font-family: ${TITLE_FONT};
  line-height: 1.1;
  font-size: ${Space[32]};
  // Add buffer as it goes on second row in small screens.
  @media (max-width: ${Breakpoints.md}) {
    text-align: center;
  }
`;

const EmphasizedText = styled.div`
  font-weight: 700;

  margin-bottom: ${Space[12]};
  @media (max-width: ${Breakpoints.md}) {
    margin-bottom: ${Space[4]};
  }
`;

interface ReadySectionProps {}

const FlexRowDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-top: ${Space[64]};

  @media (max-width: ${Breakpoints.md}) {
    flex-flow: column nowrap;
  }
`;

const FlexRowHalf = styled.div`
  width: 50%;
  @media (max-width: ${Breakpoints.md}) {
    width: 100%;
  }
`;

const SubheaderSpan = styled.span`
  font-size: ${Space[20]};
`;

// 64 padding top gives it a bit of symmetry since this section is on the bottom
// and the container already has some margin combined with SectionDiv's padding.
export const ReadySection: React.FC<ReadySectionProps> = () => (
  <FlexRowDiv>
    <FlexRowHalf>
      <CallToActionDiv>
        <EmphasizedText>Ready to go?</EmphasizedText>
        <SubheaderSpan>
          Follow us on <a href={TWITTER_URL}>Twitter</a> and join our{" "}
          <a href={DISCORD_URL}>Discord</a> to get the details on our upcoming
          drop in the first week of September as soon as we announce and for a
          chance to win exclusive presale access.
        </SubheaderSpan>
      </CallToActionDiv>
    </FlexRowHalf>
    <FlexRowHalf>
      <ResponsiveButtonBar
        buttons={[<LaunchAppButton />, <ReadWhitePaperButton />]}
      />
    </FlexRowHalf>
  </FlexRowDiv>
);
