import { DISCORD_URL, TWITTER_URL } from "../constants/urls";
import fidapepromo0 from "./assets/promo0.png";
import fidapepromo1 from "./assets/promo1.png";
import fidapepromo2 from "./assets/promo2.png";

export const HOW_IT_WORKS = [
  {
    title: "Neural network tech.",
    subtitle: "Created using deep learning.",
    body: (
      <>
        <p>
          Each Fusion Ape is individually generated using a convolutional neural
          network-based algorithm pioneered by Gatys et al. The Fidenza style,
          including colors, textures, and strokes, is used as inspiration by a
          modified VGG-19 algorithm optimized for NFT artistry.
        </p>
      </>
    ),
    image: fidapepromo0,
  },
  {
    title: "Scarcity of supply.",
    subtitle: "Only few will hold.",
    body: (
      <p>
        Only 4,200 Fusion Apes will ever be created. The creation of each unique
        Fusion Ape requires greater computational resources compared to other
        collectible projects, necessitating a higher degree of scarcity.
      </p>
    ),
    image: fidapepromo1,
  },
  {
    title: "Get ready to mint.",
    subtitle: "Join our community.",
    body: (
      <p>
        Mint is planned for Fri September 3rd, 8pm EDT. Mint price: 0.10Ξ.
        Follow us on <a href={TWITTER_URL}>Twitter</a> and join our{" "}
        <a href={DISCORD_URL}>Discord</a> to get the latest announcements right
        away and potential pre-sale access.
      </p>
    ),
    image: fidapepromo2,
  },
];
