import styled from "@emotion/styled";
import { Button } from "semantic-ui-react";
import React from "react";
import { Colors, Space } from "../constants/styles";
import { DISCORD_URL } from "../constants/urls";

const ButtonStyleDiv = styled.div`
  button {
    height: ${Space[48]};
    width: 200px;
    margin: 0 auto;
    font-size: ${Space[16]} !important;
  }
`;

const SubtextDiv = styled.div`
  margin-top: ${Space[8]};
  font-size: ${Space[12]};
  color: ${Colors.neutrals[600]};
  text-align: center;
`;

interface LandingButtonProps {
  text: string;
  subtext?: string;
  path: string;
  primary?: boolean;
}

const LandingButton: React.FC<LandingButtonProps> = ({
  text,
  subtext,
  path,
  primary,
}) => {
  return (
    <div>
      <ButtonStyleDiv>
        <a href={path}>
          {primary ? (
            <Button primary>{text}</Button>
          ) : (
            <Button inverted basic>
              {text}
            </Button>
          )}
        </a>
      </ButtonStyleDiv>
      {subtext && <SubtextDiv>{subtext}</SubtextDiv>}
    </div>
  );
};

interface LearnMoreButtonProps {
  // Ref to scroll to for learn more button.
  learnMoreRef?: React.RefObject<HTMLElement>;
}

const ReadWhitePaperButton: React.FC<LearnMoreButtonProps> = () => (
  <LandingButton text="Learn More" path="#about" />
);

const LaunchAppButton: React.FC<Partial<LandingButtonProps>> = (props) => (
  <LandingButton path={DISCORD_URL} text="Join Discord" {...props} primary />
);
export { LandingButton, LaunchAppButton, ReadWhitePaperButton };
