/**
 * A general component that displays two columns of images that alternate with
 * each step. On mobile, these get displayed as one column.
 */
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import React from "react";
import { BoxShadows, Breakpoints, Colors, Space } from "../constants/styles";

// The way that the mock works is that the image is on the left or right-side
// alternating, so this style takes a flex parameter that flips things. Also, on
// mobile, the image should simply appear below the text content.
const ContentFlexBox = styled.div<{ flip: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.flip ? "row-reverse" : "row")};
  justify-content: space-between;
  h3 {
    font-size: ${Space[36]};
    margin: 0;
    line-height: 1.5175;
  }
  h5 {
    color: ${Colors.gray[600]};
    font-size: ${Space[24]};
    margin: 0;
    line-height: 1.5175;
  }

  @media (max-width: ${Breakpoints.sm}) {
    h3 {
      font-size: ${Space[28]};
    }
    h5 {
      font-size: ${Space[20]};
    }
  }
  // Top/bottom margin differ to handle the first child being spaced nicely below
  // the total section header.
  margin: 175px 0;
  :first-of-type {
    margin-top: ${Space[96]};
  }
  :last-child {
    margin-bottom: 0;
  }
  p,
  ul {
    margin: 0.5em 0;
  }
  @media (max-width: ${Breakpoints.lg}) {
    flex-direction: column;
    p,
    ul {
      margin: 1em 0;
    }
  }
`;

const ContentFlexItem = styled.div<{ fullScreenWidthPercent: number }>`
  width: ${(props) => props.fullScreenWidthPercent}%;
  @media (max-width: ${Breakpoints.lg}) {
    width: 100%;
  }
  img {
    width: 100%;
  }
`;

const BlurbDiv = styled.div<{ flip: boolean }>`
  ${(props) => (props.flip ? "padding-right" : "padding-left")}: ${Space[48]};
  @media (max-width: ${Breakpoints.lg}) {
    padding: 0;
    margin-top: ${Space[32]};
  }

  font-size: ${Space[20]};
`;

interface AlternatingImageItemProps {
  index: number;
  title?: string;
  subtitle?: string;
  isStepIndexShown?: boolean;
  image: string;
  body: React.ReactNode;
}

const imageStyle = css`
  box-shadow: ${BoxShadows.DEFAULT};
`;

const AlternatingImageItem: React.FC<AlternatingImageItemProps> = ({
  index,
  title,
  subtitle,
  body,
  image,
  isStepIndexShown,
}) => {
  const flip = index % 2 !== 0;
  return (
    <ContentFlexBox flip={flip}>
      <ContentFlexItem fullScreenWidthPercent={53}>
        <img
          className={imageStyle}
          src={image}
          alt="Fusion Ape"
          loading="lazy"
        />
      </ContentFlexItem>

      <ContentFlexItem fullScreenWidthPercent={40}>
        <BlurbDiv flip={flip}>
          {title && (
            <h3>
              {isStepIndexShown && <>{index + 1}. </>}
              {title}
            </h3>
          )}
          {subtitle && <h5>{subtitle}</h5>}
          <div>{body}</div>
        </BlurbDiv>
      </ContentFlexItem>
    </ContentFlexBox>
  );
};

interface AlternatingImageItemsSpec {
  spec: Omit<AlternatingImageItemProps, "index" | "isStepIndexShown">[];

  // If title is present and this is true, shows the index number as if it were
  // a step. e.g., "1. Memorize"
  isStepIndexShown?: boolean;
}
export const AlternatingImageItemsSection: React.FC<
  AlternatingImageItemsSpec
> = ({ spec, isStepIndexShown }) => (
  <React.Fragment>
    <div id="about" style={{ position: "relative" }} />
    <div>
      {spec.map((item, i) => (
        <AlternatingImageItem
          key={i}
          index={i}
          isStepIndexShown={isStepIndexShown}
          {...item}
        />
      ))}
    </div>
  </React.Fragment>
);
