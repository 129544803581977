import * as React from "react";
import { useAppDispatch, useAppSelector } from "../slices";
import { closeModal as closeModalAction } from "../slices/web3Modal";
import { setUserData as setUserDataAction, UserData } from "../slices/web3User";

export interface Web3ProviderModalProps {
  closeModal: () => void;
  shown: boolean;
  setUserData(d: UserData): void;
}

interface ConnectorProps {
  Render: React.FunctionComponent<Web3ProviderModalProps>;
}

export const Web3ProviderModalConnector: React.FunctionComponent<
  ConnectorProps
> = ({ Render }) => {
  const modalShown = useAppSelector((state) => state.web3Modal.shown);
  const dispatch = useAppDispatch();
  const closeModal = () => dispatch(closeModalAction());

  const setUserData = (d: UserData) => dispatch(setUserDataAction({ data: d }));
  return (
    <Render
      closeModal={closeModal}
      shown={modalShown}
      setUserData={setUserData}
    />
  );
};
