import { css } from "@emotion/css";
import * as React from "react";
import { Modal } from "semantic-ui-react";
import { FadeInOutDiv } from "../components/FadeInOutDiv";
import Web3ProviderItemGroup from "./Web3ProviderItemGroup";
import { Web3ProviderModalProps } from "./Web3ProviderModalConnector";

const modalStyle = css`
  z-index: 9999 !important;
`;

export const Web3ProviderModal: React.FunctionComponent<Web3ProviderModalProps> = ({
  shown,
  closeModal,
  setUserData,
}) => {
  return (
    <Modal closeIcon className={modalStyle} open={shown} onClose={closeModal}>
      <Modal.Header>Select a provider</Modal.Header>

      <Modal.Content>
        <FadeInOutDiv fadeInOnly duration={0.3}>
          <Web3ProviderItemGroup
            setUserData={setUserData}
            closeModal={closeModal}
          />
        </FadeInOutDiv>
      </Modal.Content>
    </Modal>
  );
};
