import * as React from "react";
import { Item } from "semantic-ui-react";
import { useWeb3React } from "@web3-react/core";
import { NoEthereumProviderError } from "@web3-react/injected-connector";
import Web3ProviderItem from "./Web3ProviderItem";
import { METAMASK_INSTALL_URL } from "../constants/urls";
import { web3ProviderTypes } from "../types";
import { UserData } from "../slices/web3User";
import { useMetamaskInstalled, useWalletConnectors } from "./walletHooks";
import metamaskPng from "../assets/metamask.png";

export interface Web3ProviderItemGroupProps {
  closeModal: () => void;
  setUserData(d: UserData): void;
}

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

const Web3ProviderItemGroup: React.FunctionComponent<
  Web3ProviderItemGroupProps
> = ({ closeModal, setUserData }) => {
  const openMetamaskWebsite = () => openInNewTab(METAMASK_INSTALL_URL);

  const context = useWeb3React();
  const { error } = context;

  const asyncMetamaskInstallStatus = useMetamaskInstalled();
  const metamaskNotInstalled =
    error instanceof NoEthereumProviderError || !asyncMetamaskInstallStatus;

  const {
    handleMetaMask,
    handleWalletConnect,
    handleWalletLink,
  } = useWalletConnectors();

  return (
    <Item.Group divided>
      <Web3ProviderItem
        imageSrc={metamaskPng}
        header={metamaskNotInstalled ? "Install Metamask" : "Connect Metamask"}
        meta="Securely connect to the Ethereum network using the Metamask browser extension."
        providerType={web3ProviderTypes.Metamask}
        onClick={metamaskNotInstalled ? openMetamaskWebsite : handleMetaMask}
      />
    </Item.Group>
  );
};

export default Web3ProviderItemGroup;
