import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { web3ProviderTypes } from "../types";

export interface UserData {
  providerType: web3ProviderTypes | null;
  isLoggedIn: boolean;
  userAddress: string | null;
}

export interface WalletInstallationState {
  isMetamaskInstalled: boolean;
}

const initialState: UserData & WalletInstallationState = {
  userAddress: "",
  providerType: null,
  isLoggedIn: false,
  isMetamaskInstalled: false,
};

export const web3UserSlice = createSlice({
  name: "web3UserData",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<{ data: UserData }>) => {
      state.isLoggedIn = true;
      state.providerType = action.payload.data.providerType;
      state.userAddress = action.payload.data.userAddress;
    },
    setMetamaskInstallationState: (
      state,
      action: PayloadAction<{ isInstalled: boolean }>
    ) => {
      state = {
        ...state,
        isMetamaskInstalled: action.payload.isInstalled,
      };
    },
  },
});

export const {
  setUserData,
  setMetamaskInstallationState,
} = web3UserSlice.actions;

export default web3UserSlice.reducer;
