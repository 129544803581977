import { createSlice } from "@reduxjs/toolkit";

interface Web3ModalState {
  shown: boolean;
}

const initialState: Web3ModalState = {
  shown: false,
};
export const web3ModalSlice = createSlice({
  name: "web3Modal",
  initialState,
  reducers: {
    openModal: (state) => {
      state.shown = true;
    },
    closeModal: (state) => {
      state.shown = false;
    },
  },
});

export const { openModal, closeModal } = web3ModalSlice.actions;

export default web3ModalSlice.reducer;
