import React from "react";
import styled from "@emotion/styled";
import { Container } from "./Layout.styled";
import { Space, Breakpoints, Colors } from "../constants/styles";

const MaybeColoredDiv = styled.div<{ withBackgroundColor?: boolean }>`
  font-family: "Montserrat", sans-serif;
  h1 {
    font-family: "Montserrat", sans-serif;
  }
  h3 {
    font-family: "Montserrat", sans-serif;
  }
  width: 100%;
  background-color: ${(props) =>
    props.withBackgroundColor ? Colors.gray[900] : Colors.gray[100]};
  color: ${(props) =>
    props.withBackgroundColor
      ? Colors.whiteAlpha[900]
      : Colors.blackAlpha[900]};
  h5 {
    font-family: "Montserrat", sans-serif;
    color: ${(props) =>
      props.withBackgroundColor
        ? Colors.whiteAlpha[800]
        : Colors.blackAlpha[800]};
  }
`;

const SectionDiv = styled(Container)`
  padding: ${Space[96]} 0px ${Space[128]};
  // Similar to the padding in the MainContent container, but because we need the
  // background color to fill out correctly, we use the disableBreakpoints for
  // this particular page.
  @media (max-width: ${Breakpoints.xl}) {
    padding-left: ${Space[12]};
    padding-right: ${Space[12]};
  }
  text-align: left;
`;

interface LandingSectionProps {
  withBackgroundColor?: boolean;
  // Style overrides for the inner section.
  sectionStyle?: React.CSSProperties;
  children: React.ReactNode;
}
// This whole forwardRef business is to make any of these sections
// scrollable-to using a ref.
export const LandingSection = React.forwardRef<any, LandingSectionProps>(
  ({ children, sectionStyle, withBackgroundColor }, ref) => {
    // The outer div needs to fill 100% for coloring to work properly. The inner
    // uses BreakpointsContainer.
    return (
      <MaybeColoredDiv withBackgroundColor={withBackgroundColor} ref={ref}>
        <SectionDiv style={sectionStyle}>{children}</SectionDiv>
      </MaybeColoredDiv>
    );
  }
);
