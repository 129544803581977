import { FunctionComponent } from "react";

export interface ConnectorProps<TProps> {
  Render: FunctionComponent<TProps>;
}

export enum web3ProviderTypes {
  Metamask = "Metamask",
  Fortmatic = "Fortmatic",
  CoinbaseWallet = "Coinbase Wallet",
  WalletConnect = "Wallet Connect",
}
